<template>
  <div>
    <v-snackbar top :timeout="2000" :color="snack.color" v-model="snack.snackbar">
      {{ snack.msg }}
    </v-snackbar>
    <v-container>
      <v-row>
        <v-col>
          <v-img
            :src="require('@/assets/images/print.gif')"
            
            max-height="200"
          ></v-img>
        </v-col>
      </v-row>
      <v-row v-show="formatPrice(b_rate) != 0.00" style="font-size:13px;padding:10px;">
        <v-col cols="9"><label>{{ b_itemname }}</label></v-col> 
        <v-col cols="3" style="text-align:right ;">
            <span class="pull-right" style="text-align:right ;">
          <label style="text-align:right ;">&nbsp;&nbsp;{{b_itemcode}}<small style="color:red"></small></label></span>
            </v-col>  
      </v-row>
      <v-col cols="12" sm="6" md="6" style="padding-bottom:0px;padding-top:0px;">
        <v-text-field
          v-model="passData.barcode"
          label="Barcode"
          outlined
          append-icon="mdi-barcode"
          @click:append="scanbarcode"
          autofocus
          @change="getItemname"
          dense
        ></v-text-field>
        <v-text-field
          v-model="passData.count"
          label="Number of Slips"
          outlined
          type="number"
          ref="count"
          dense
        ></v-text-field>
        
      </v-col>
      <v-col cols="12" style="text-align:center;padding-bottom:0px;padding-top:0px;">
              Print Details
           
    </v-col>
    <v-row>
    <v-col cols="6" class="text-center" style="font-size:0.5em !important;padding-bottom:0px;padding-top:0px;">
     
    <v-switch
    v-model="passData.retailrate"
    label="Retail Rate "
    color="red"
     :input-value="false"
     hide-details></v-switch>
    </v-col>
    <v-col cols="6" class="text-center" style="padding-bottom:0px;padding-top:0px;">
      <v-switch
    v-model="passData.cost"
    label="Cost"
    color="green"
     :input-value="false"
     hide-details
   
  ></v-switch>
   
    </v-col>
    <v-col cols="12" class="text-center">
    
   
      <v-switch
      v-model="passData.retailratewithtax"
      label="Retail Rate with Tax"
      color="orange"
       :input-value="false"
       hide-details
     
    ></v-switch>
    </v-col>
    </v-row>
    <v-col cols="12" class="text-center">
      <v-btn color="info" @click="Print_" :disabled="isSubmitting" v-show="!isSubmitting">
        <v-icon dark>{{ submitIcon }} </v-icon>
      </v-btn>
    </v-col>
    </v-container>
    <v-dialog v-model="BarcodeModal" persistent width="500">
      <v-card>
        <v-card-title
          style="color: rgb(0 99 145); font-size: 17px; text-align: center"
          >Scan barcode</v-card-title
        >
  
        <v-card-text>
          <StreamBarcodeReader
            @decode="(a) => onDecode(a)"
            @loaded="() => onLoaded()"
          ></StreamBarcodeReader>
        </v-card-text>
        <v-card-actions hidden>
          <v-spacer></v-spacer>
          <v-btn
            small
            @click="BarcodeModal = false"
            style="background-color: #fb8c00; color: #fff"
          >
            Cancel
          </v-btn>
  
          <v-btn
            small
            @click="BarcodeModal = false"
            style="background-color: #2e7d32; color: #fff"
          >
            Ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      URL__: localStorage.getItem("BaseURL"),
      ios: false,
      b_itemname: "",
      b_rate: "",
      b_itemcode: "",
      Currency: localStorage.getItem("Currency"),
      passData: {
        barcode:  localStorage.getItem("barcode") || '',
        count: "",
        retailrate:true,
        retailratewithtax:true,
        cost:false,
      },
      printName:localStorage.getItem('bPrinter'),
      printerType :localStorage.getItem('btype'),
      BarcodeModal: false,
      snack:{
        snackbar:false,
        msg:'',
        color:"red"
      },
      isSubmitting: false
    };
  },
  mounted() {
    this.string = this.URL__;
    this.substring = "https";
    this.Check_url = this.string.includes(this.substring);
    if (this.Check_url == true) {
      // alert(this.Check_url)
      this.ios = this.Check_url;
    } else {
      this.ios = this.Check_url;
    }
    if( localStorage.getItem("HideCost"))
     {
      this.passData.cost = false
     }
     this.passData.cost = true
if(localStorage.getItem("pbarcode") !== null) 
{
   this.passData.barcode = localStorage.getItem("pbarcode");
   this.b_itemname = localStorage.getItem("pitemname");
   this.b_rate = localStorage.getItem("prate");
   this.b_itemcode = localStorage.getItem("pitemcode_"); 
                 
    // alert("y null"+localStorage.getItem("pbarcode"))
    // localStorage.removeItem("pbarcode")
    // this.getItemname
}
    window.ResultPrint = this.ResultPrint;
    window.getResultPrint = this.getResultPrint;
    window.errorDisplay = this.errorDisplay;
  },
  computed: {
    submitIcon() {
      return this.isSubmitting ? 'mdi-timer-sand' : 'mdi-printer';
    }
  },
  methods: {
    formatPrice(value) {
      this.number = localStorage.getItem("symbol");
      let val = (value / 1).toFixed(this.number).replace(".", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    scanbarcode() {
      //alert("aaa");
      this.BarcodeModal = true;
    },
    onDecode(a) {
      //console.log("Result Barcode " + a);
      // this.text = a;
      if (a != null || a != "") {
        this.passData.barcode = a;
        this.BarcodeModal = false;
      }
    },
    Print_() {
      this.isSubmitting =true;
      if(!this.passData.count)
      {
        this.snack.msg = "Enter Number Slips";
        this.snack.snackbar = true;
        this.$refs.count.focus();
        this.isSubmitting =false
      }else{
        // alert("n"+this.passData.count)
        if (this.passData.barcode == null || this.passData.barcode == "") {
          this.snack.msg = "Enter All Details";
         this.snack.snackbar = true;
          // alert("Error")
          this.isSubmitting = false
        }
        else if (this.passData.count == null || this.passData.count == "" || !this.passData.count) {
          this.snack.msg = "Enter All Details";
          this.snack.snackbar = true;
          // alert("")
          this.isSubmitting = false
        }else{
         if (this.ios == true) {
        
        this.axios
          .get(
            this.URL__ +
            "/api/item/printbarcode?barcode="+this.passData.barcode+"&slips="+this.passData.count+"&BarcodePrinter="+this.printName+"&barcodetype=" + this.printerType+"&ShowRetailRate="+this.passData.retailrate+"&IsRetailRateWithTax="+this.passData.retailratewithtax+"&ShowCost="+this.passData.cost
,
            null
          )
          .then(
            (response) => {
              console.log("aa"+JSON.stringify(response.data))

              if(response.data.message === "Success")
              {
                this.snack.msg = "Success";
                this.snack.snackbar = true;
                this.snack.color = "green";
                this.isSubmitting = false;
                const a = JSON.stringify(response.data);
              if (a != '"Barcode deos not exist"') {
                // this.b_itemname = response.data.ItemName;
                // this.b_rate = response.data.RetailRate;
              } else {
                this.showw = false;
                // alert("Item Not Found !")
                this.snack.msg = "Item Not Found !";
                this.snack.snackbar = true;
                this.isSubmitting =false;
                setTimeout(() => {
                  window.history.back();
                }, 2000);
              }
              }
              
            },
            (error) => {
              this.showw = false;
              if (error.response.status == 404) {
                this.showw = false;
                this.isSubmitting = false
              }
              
              // this.error = "Something went wrong..!";
            }
            
          );
      } else {
        //------------------------------------------------------------------------
          // this.b_itemname = "";
          // this.b_rate = "";
        const myobj = {
          barcode: this.passData.barcode,
        };
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "ResultPrint";
        var url_ =  this.URL__ + "/api/item/printbarcode?barcode="+this.passData.barcode+"&slips="+this.passData.count+"&BarcodePrinter="+this.printName+"&barcodetype=" + this.printerType+"&ShowRetailRate="+this.passData.retailrate+"&IsRetailRateWithTax="+this.passData.retailratewithtax+"&ShowCost="+this.passData.cost
;
     
        var JsonData = {
          Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        window.interface.CommonMethod(this.SendOBJ);
       }
        }
      }
      
        
    },
      ResultPrint(res)
    {
      let obj = JSON.parse(res);
     
             if(obj.message === "Success")
              {
                this.snack.msg = "Success";
                this.snack.snackbar = true;
                this.snack.color = "green";
              }else{
                this.snack.msg = "Something went wrong !";
                this.snack.snackbar = true;
                this.snack.color = "red";
              }
               this.isSubmitting =false;
               
    },
    getItemname() {
      this.isSubmitting =false;
      if (this.ios == true) {
          // this.b_itemname = "";
          // this.b_rate = "";
        this.axios
          .get(
            this.URL__ +
              "/api/item/getitembybarcode?barcode=" +
              this.passData.barcode,
            null
          )
          .then(
            (response) => {
              console.log("stts"+ response.status);
              if (response.status === 200) {
              console.log("Result" + JSON.stringify(response.data));
              const a = JSON.stringify(response.data);
              if (a != '"Barcode deos not exist"') {
                this.b_itemname = response.data.ItemName;
                this.b_itemcode = response.data.ItemCode;
              } else {
                this.showw = false;
                // alert("Item Not Found !")
                this.snack.msg = "Item Not Found !";
                this.snack.snackbar = true;

                setTimeout(() => {
                  window.history.back();
                }, 2000);
              }
            }else{
                this.snack.msg = "Item Not Found !";
                this.snack.snackbar = true;
                this.b_itemname = "";
                this.b_itemcode = "";
            }
            },
            (error) => {
              this.snack.msg = "Item Not Found !";
              this.snack.snackbar = true;
              this.isSubmitting = true;
              this.b_itemname = "";
              this.b_itemcode = "";
              this.showw = false;
              if (error.response.status == 404) {
                this.showw = false;
              }
              // this.error = "Something went wrong..!";
            }
          );
      } else {
        //------------------------------------------------------------------------
          this.b_itemname = "";
          this.b_itemcode = "";
        const myobj = {
          barcode: this.passData.barcode,
        };
        var obj = JSON.stringify(myobj);
        var method = "Get";
        var funtn_ = "getResultPrint";
        var url_ =
          this.URL__ +
          "/api/item/getitembybarcode?barcode=" +
          this.passData.barcode;

        var JsonData = {
          Json: [{ URL: url_, Method: method, Params: obj, Function: funtn_ }],
        };

        var aaa = JSON.stringify(JsonData);
        this.SendOBJ = aaa;
        window.interface.CommonMethod(this.SendOBJ);
      }
    },

    getResultPrint(res) {
      let obj = JSON.parse(res);
      this.b_itemname = obj.ItemName;
      // this.b_rate = obj.RetailRate;
      this.b_itemcode = obj.ItemCode;
      // alert("Res "+obj.ItemName)
    },
    errorDisplay()
    {
      this.snack.msg = "Item Not Found !";
      this.snack.snackbar = true;
      this.isSubmitting = true;
      this.b_itemname = "";
      this.b_itemcode = "";

    }
  
  },
};
</script>