<template>
    <div class="">
         <Header />
         <printbarcode />
    </div>
</template>
<script>
import Header from "../components/Header.vue";
import printbarcode from '../components/PrintBarcode_c.vue';
export default {
    components: {
        Header,
        printbarcode,
  
  },
     data() {
      return {
        
      }
  }
}

</script>